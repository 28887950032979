<template>
<div>
    <div class="row page-title">
        <label>{{pageTitle}}</label>
    </div>
    <div class="page-details row">
        <media-vendor-overview class="col-lg-8" :VendorValidationFiles="VendorValidationFiles" :Request="Request" :ID="id"></media-vendor-overview>
        <div class="col-lg-4" v-if="IsDataLoaded">
            <div class="rounded py-2 px-1">
                <b-alert class="alert-info-new position-fixed fixed-top m-0 rounded-0" variant="info" dismissible :show="!IsApproveOrRejectEnabled">
                    The Request is already completed.
                </b-alert>
                <p class="approve-reject-label px-3 pt-2 pb-1">Complete The New Media Vendor</p>
                <p class="approve-reject-content px-3 pt-2 pb-1">
                    When approved, this request will be completed.
                </p>
                <div class="px-3">
                    <label for="completedcode">Completed Code</label>
                    <b-input
                        v-bind:class="{
                            errorborder: $v.CompletedCode.$invalid && $v.CompletedCode.$dirty,
                            noterrorborder: !$v.CompletedCode.$invalid,
                        }"
                        @blur="$v.CompletedCode.$touch()"
                        @focus="$v.CompletedCode.$reset()"
                        id="completedcode" name="completedcode" v-model="CompletedCode" placeholder="Enter Completed Code" />
                    <div class="error-message-format required-field" v-if="!$v.CompletedCode.required && $v.CompletedCode.$dirty">
                        Required field
                    </div>
                </div>
                <div class="text-right pb-3 pr-3" style="width: 100%;padding-top: 10px;">
                    <button v-if="IsApproveOrRejectEnabled" class="btn custom-button btn-primary mx-2" @click="Approve()">
                        Complete
                    </button>
                    <button v-else class="btn custom-button btn-primary mx-2" disabled="disabled" title="The request is already completed.">
                        Complete
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import store from "@/store";
import mediaVendorMixin from "../../../mixins/mediavendorworkflowvauthorization";
import * as status from "../../../utilities/VendorWorkflowStates";
import { required } from "vuelidate/lib/validators";
export default {
    name: "Complete",
    validations: {
        CompletedCode: {
            required,
        },
    },
    mixins: [mediaVendorMixin],
    data() {
        return {
            Request: {},
            VendorValidationFiles: [],
            CompletedCode: "",
        };
    },
    props: ["id"],
    computed: {
        RequestedCode() {
            return this.Request ? this.Request.ProductCode : "";
        },
        canApproveCurrentWorkflowRequest() {
            return this.$store.getters.canCompleteVendorRequest;
        },
        IsApproveOrRejectEnabled() {
            return this.Request && this.Request.Status == status.FinancialSystemApproval;
        },
        IsDataLoaded() {
            return this.Request && this.Request.VendorBusinessName ? true : false;
        },
    },
    methods: {
        GetData() {
            return {
                ID: parseInt(this.id),
                ActiveTaskID: this.Request.ActiveTaskID | 0,
                ProjectID: this.Request.OmniflowProjectID | 0,
                ProcessID: this.Request.OmniflowProcessID | 0,
                CompletedCode: this.CompletedCode
            };
        },
        Approve() {
            this.$v.CompletedCode.$touch();
            if(!this.$v.$invalid){
            this.$store
                .dispatch("mediavendor/CompleteVendorRequest", this.GetData())
                .then(() => {
                    store.dispatch(
                        "progress/setSuccessMessage",
                        `The request ${this.pageTitle} has been approved`
                    );
                    this.$router.push("/VendorRequests");
                })
                .catch((ex) => {
                    if (ex.response) {
                        this.SetErrorMessage(
                            "Error while approving request",
                            ex.response.data
                        );
                    } else {
                        this.SetErrorMessage("Error while approving request", ex);
                    }
                });
            }
        },
        SetErrorMessage(message, ex) {
            store.dispatch(
                "progress/setErrorMessage",
                `${message} ${this.RequestedCode}. The error is ${ex}`
            );
        },
    },
};
</script>

<style scoped>
.reject-section {
    padding-bottom: 10px;
}

@media screen and (min-width: 1200px) {
    .page-details {
        padding: 1rem 3rem 0px !important;
    }
}

.row.page-title {
    height: 55px;
    background-color: #eef3f5 !important;
}

.row.page-title>label {
    padding: 14px 0px 14px 55px !important;
    font-family: "Open Sans";
    font-size: 20px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.81px;
    color: #4d5161;
    text-transform: none !important;
}

.review-component>div:first-child {
    box-shadow: 0 1px 3px 0 rgb(9 30 66 / 12%) !important;
    border: solid 1px rgba(239, 242, 244, 0.2) !important;
    border-right: none;
    border-radius: 8px 0px 0px 8px;
    background-color: #ffffff;
}
</style>
